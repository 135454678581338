import { TypedDocumentNode } from '../../dto-types'
import { graphql } from '../../dto-types/src/gql/gql'
import {
  ActivityQuery,
  ActivityQueryVariables,
  ActivityTaskQuery,
  ActivityTaskQueryVariables,
  FilterAuditTrailByEntityIdsQuery,
  FilterAuditTrailByEntityIdsQueryVariables,
  FilterAuditTrailQuery,
  FilterAuditTrailQueryVariables,
  GetActiveActivitiesForOrganizationQuery,
  GetActiveActivitiesForOrganizationQueryVariables,
  GetActiveNodeForTaskQuery,
  GetActiveNodeForTaskQueryVariables,
  GetActivitiesQuery,
  GetActivitiesQueryVariables,
  GetActivitySubscriptionsForActivityQuery,
  GetActivitySubscriptionsForActivityQueryVariables,
  GetActivityTaskAttachmentDownloadUrlQuery,
  GetActivityTaskAttachmentDownloadUrlQueryVariables,
  GetActivityTasksByFilterQuery,
  GetActivityTasksByFilterQueryVariables,
  GetActivityTasksForFintechQuery,
  GetActivityTasksForFintechQueryVariables,
  GetActivityTasksForPartnerTodoQuery,
  GetActivityTasksForPartnerTodoQueryVariables,
  GetActivityTasksForSubscriberQuery,
  GetActivityTasksForSubscriberQueryVariables,
  GetActivityTasksForTodoQuery,
  GetActivityTasksForTodoQueryVariables,
  GetActivityTasksStatusCountQuery,
  GetActivityTasksStatusCountQueryVariables,
  GetAdminBankReportQuery,
  GetAdminBankReportQueryVariables,
  GetAllFragmentsForPartnershipIdQuery,
  GetAllFragmentsForPartnershipIdQueryVariables,
  GetAllFragmentsPartnerSourceIdQuery,
  GetAllFragmentsPartnerSourceIdQueryVariables,
  GetAllMosaicPartnershipsQuery,
  GetAllMosaicPartnershipsQueryVariables,
  GetAllRevisionsQuery,
  GetAllRevisionsQueryVariables,
  GetAssetDownloadUrlQuery,
  GetAssetDownloadUrlQueryVariables,
  GetAssetEditsQuery,
  GetAssetEditsQueryVariables,
  GetAssetUploadUrlQuery,
  GetAssetUploadUrlQueryVariables,
  GetAssetVersionsQuery,
  GetAssetVersionsQueryVariables,
  GetCommentThreadQuery,
  GetCommentThreadQueryVariables,
  GetCommentThreadsQuery,
  GetCommentThreadsQueryVariables,
  GetCurrentAssetVersionsQuery,
  GetCurrentAssetVersionsQueryVariables,
  GetCurrentUserQuery,
  GetCurrentUserQueryVariables,
  GetEnrichedFragmentByFragmentIdQuery,
  GetEnrichedFragmentByFragmentIdQueryVariables,
  GetEnrichedTiktokPostFragmentQuery,
  GetEnrichedTiktokPostFragmentQueryVariables,
  GetFragmentByIdQuery,
  GetFragmentByIdQueryVariables,
  GetIntegrationQuery,
  GetIntegrationQueryVariables,
  GetIntegrationsQuery,
  GetIntegrationsQueryVariables,
  GetIssueQuery,
  GetIssueQueryVariables,
  GetIssuesByPartnershipQuery,
  GetIssuesByPartnershipQueryVariables,
  GetIssuesByPartnerSourceIdQuery,
  GetIssuesByPartnerSourceIdQueryVariables,
  GetMosaicPartnershipsForOrganizationQuery,
  GetMosaicPartnershipsForOrganizationQueryVariables,
  GetNewAssetVersionUploadUrlQuery,
  GetNewAssetVersionUploadUrlQueryVariables,
  GetNotificationPreferencesProjectQuery,
  GetNotificationPreferencesProjectQueryVariables,
  GetNotificationPreferencesSpaceQuery,
  GetNotificationPreferencesSpaceQueryVariables,
  GetNotificationPreferencesUserQuery,
  GetNotificationPreferencesUserQueryVariables,
  GetOrganizationByTeamUrlQuery,
  GetOrganizationByTeamUrlQueryVariables,
  GetOrganizationDashboardQuery,
  GetOrganizationDashboardQueryVariables,
  GetOrganizationInvitationQuery,
  GetOrganizationInvitationQueryVariables,
  GetOrganizationLogoUploadUrlQuery,
  GetOrganizationLogoUploadUrlQueryVariables,
  GetOrganizationQuery,
  GetOrganizationQueryVariables,
  GetOrganizationsQuery,
  GetOrganizationsQueryVariables,
  GetPartnershipsForOrganizationQuery,
  GetPartnershipsForOrganizationQueryVariables,
  GetPartnerSourceByIdQuery,
  GetPartnerSourceByIdQueryVariables,
  GetPartnerSourcesForPartnershipQuery,
  GetPartnerSourcesForPartnershipQueryVariables,
  GetPartnerSourcesWithCountsForPartnershipQuery,
  GetPartnerSourcesWithCountsForPartnershipQueryVariables,
  GetProcessesQuery,
  GetProcessesQueryVariables,
  GetProcessQuery,
  GetProcessQueryVariables,
  GetProductsByOrganizationQuery,
  GetProductsByOrganizationQueryVariables,
  GetProgramByIdQuery,
  GetProgramByIdQueryVariables,
  GetProgramsForOrganizationQuery,
  GetProgramsForOrganizationQueryVariables,
  GetProjectByIdQuery,
  GetProjectByIdQueryVariables,
  GetProjectInvitationQuery,
  GetProjectInvitationQueryVariables,
  GetProjectQuery,
  GetProjectQueryVariables,
  GetProjectTaskAuditTrailByWorkflowTemplateQuery,
  GetProjectTaskAuditTrailByWorkflowTemplateQueryVariables,
  GetProjectTaskQuery,
  GetProjectTaskQueryVariables,
  GetReportQuery,
  GetReportQueryVariables,
  GetReviewRequestsQuery,
  GetReviewRequestsQueryVariables,
  GetRevisionQuery,
  GetRevisionQueryVariables,
  GetRuleByPartnerSourceQuery,
  GetRuleByPartnerSourceQueryVariables,
  GetSidebarQuery,
  GetSidebarQueryVariables,
  GetSlackChannelQuery,
  GetSlackChannelQueryVariables,
  GetSlackChannelsQuery,
  GetSlackChannelsQueryVariables,
  GetSlackDataChannelQuery,
  GetSlackDataChannelQueryVariables,
  GetSlackTeamInfoQuery,
  GetSlackTeamInfoQueryVariables,
  GetSlackUsersQuery,
  GetSlackUsersQueryVariables,
  GetSpaceByUrlQuery,
  GetSpaceByUrlQueryVariables,
  GetSpaceInvitationQuery,
  GetSpaceInvitationQueryVariables,
  GetSpaceInvitationsForSpaceQuery,
  GetSpaceInvitationsForSpaceQueryVariables,
  GetSpacesQuery,
  GetSpacesQueryVariables,
  GetSponsorQuery,
  GetSponsorQueryVariables,
  GetSubscriptionsForOrganizationQuery,
  GetSubscriptionsForOrganizationQueryVariables,
  GetSuggestionsForAssetQuery,
  GetSuggestionsForAssetQueryVariables,
  GetTasksForUserAndOrganizationQuery,
  GetTasksForUserAndOrganizationQueryVariables,
  GetWorkflowTemplateQuery,
  GetWorkflowTemplateQueryVariables,
  GetWorkflowTemplatesQuery,
  GetWorkflowTemplatesQueryVariables,
  OrganizationInvitationsQuery,
  OrganizationInvitationsQueryVariables,
  OrganizationsByTypeQuery,
  OrganizationsByTypeQueryVariables,
  ProjectInvitationsForProjectQuery,
  ProjectInvitationsForProjectQueryVariables,
  ProjectInvitationsForUserQuery,
  ProjectInvitationsForUserQueryVariables,
  ProjectTaskAttachmentDownloadUrlQuery,
  ProjectTaskAttachmentDownloadUrlQueryVariables,
  RequirementCategorySummariesQuery,
  RequirementCategorySummariesQueryVariables,
  ResolveSuggestionQuery,
  ResolveSuggestionQueryVariables,
  SearchQuery,
  SearchQueryVariables,
  SentOrganizationInvitationsQuery,
  SentOrganizationInvitationsQueryVariables,
  SentProjectInvitationsQuery,
  SentProjectInvitationsQueryVariables,
} from '../../dto-types/src/gql/graphql'

export const GET_CURRENT_USER: TypedDocumentNode<GetCurrentUserQuery, GetCurrentUserQueryVariables> = graphql(`
  query GetCurrentUser {
    me {
      id
      email
      firstName
      lastName
      role
      organizationUsers {
        role
        createdAt
        organizationId
        organization {
          name
          type
          teamUrl
        }
      }
      avatarUrl
      externalId
    }
  }
`)

export const GET_ORGANIZATIONS: TypedDocumentNode<GetOrganizationsQuery, GetOrganizationsQueryVariables> = graphql(`
  query GetOrganizations {
    organizations {
      id
      name
      teamUrl
      logoUrl
      metadata
      type

      sponsor {
        id
        onboardingStartTime
        onboardingFinishTime
      }
      users {
        id
        role
        createdAt
        user {
          externalId
          id
          firstName
          lastName
          email
          avatarUrl
          firstLoggedInAt
        }
      }
      spaces {
        id
        name
        spaceUrl
        icon
        iconColor
      }
    }
  }
`)

export const GET_ORGANIZATION_FOR_MOSAIC_ADMIN: TypedDocumentNode<GetOrganizationQuery, GetOrganizationQueryVariables> =
  graphql(`
    query GetOrganizationForMosaicAdmin($id: String!) {
      organizationForMosaicAdmin(input: { id: $id }) {
        id
        name
        logoUrl
        type
        users {
          id
          role
          createdAt
          user {
            externalId
            id
            firstName
            lastName
            email
            avatarUrl
            firstLoggedInAt
          }
        }
      }
    }
  `)

export const GET_ORGANIZATION: TypedDocumentNode<GetOrganizationQuery, GetOrganizationQueryVariables> = graphql(`
  query GetOrganization($id: String!) {
    organization(input: { id: $id }) {
      id
      name
      logoUrl
      type
      users {
        id
        role
        title
        createdAt
        user {
          externalId
          id
          firstName
          lastName
          email
          avatarUrl
          firstLoggedInAt
        }
      }
    }
  }
`)

export const GET_ORGANIZATION_DASHBOARD: TypedDocumentNode<
  GetOrganizationDashboardQuery,
  GetOrganizationDashboardQueryVariables
> = graphql(`
  query GetOrganizationDashboard($organizationId: String!) {
    projectsByUserInOrg(organizationId: $organizationId) {
      id
      name
      projectUrl
      icon
      iconColor
      archivedAt
      users {
        id
        user {
          id
          firstName
          lastName
          avatarUrl
          email
        }
      }
      space {
        id
        name
        spaceUrl
      }
    }
    projectTasksCreatedInOrg(organizationId: $organizationId) {
      id
    }
    projectTasksAssignedInOrg(organizationId: $organizationId) {
      id
      name
      description
      dueDate
      archivedAt
      priority
      project {
        name
        icon
        iconColor
        projectUrl
        space {
          spaceUrl
        }
      }
    }
  }
`)

export const GET_ORGANIZATION_BY_TEAM_URL: TypedDocumentNode<
  GetOrganizationByTeamUrlQuery,
  GetOrganizationByTeamUrlQueryVariables
> = graphql(`
  query GetOrganizationByTeamUrl($teamUrl: String!) {
    getOrganizationByTeamUrl(teamUrl: $teamUrl) {
      id
      name
      teamUrl
      type
      users {
        id
        role
        user {
          id
          firstName
          lastName
          email
        }
      }
      spaces {
        id
        name
      }
    }
  }
`)

export const GET_SPACE_BY_URL: TypedDocumentNode<GetSpaceByUrlQuery, GetSpaceByUrlQueryVariables> = graphql(`
  query GetSpaceByUrl($spaceUrl: String!, $organizationId: String!) {
    spaceByUrl(spaceUrl: $spaceUrl, organizationId: $organizationId) {
      id
      name
      spaceUrl
      description
      isPublic
      isOnboarded
      createdAt
      icon
      iconColor
      owner {
        id
        firstName
        lastName
      }
      projects {
        id
        name
        projectUrl
        description
        createdAt
        updatedAt
        iconUrl
        icon
        iconColor
        tasks {
          id
          name
          description
          status
          dueDate
          archivedAt
          assignee {
            id
            avatarUrl
            firstName
            lastName
          }
        }
        users {
          id
          role
          user {
            id
            avatarUrl
            firstName
            lastName
            email
          }
        }
      }
      users {
        id
        role
        user {
          id
          avatarUrl
          firstName
          lastName
          email
        }
      }
    }
  }
`)

export const GET_PROJECT_BY_URL: TypedDocumentNode<GetProjectQuery, GetProjectQueryVariables> = graphql(`
  query GetProject($spaceId: String!, $projectUrl: String!) {
    projectByUrl(spaceId: $spaceId, projectUrl: $projectUrl) {
      id
      name
      projectUrl
      description
      isPublic
      createdAt
      icon
      iconColor
      createdBy {
        firstName
        lastName
        id
      }
      space {
        id
        name
        spaceUrl
      }
      tasks {
        id
        name
        description
        dueDate
        priority
        status
        archivedAt
        updatedAt
        assignee {
          id
          firstName
          lastName
          avatarUrl
        }
        reviewer {
          id
          firstName
          lastName
        }
        comments {
          id
          content
          createdAt
          author {
            id
            firstName
            lastName
            avatarUrl
            email
          }
        }
        attachments {
          id
          fileKey
          name
        }
        createdAt
        workflow {
          status
          currentNode {
            status
            type
          }
        }
      }
      users {
        id
        user {
          id
          firstName
          lastName
          email
          avatarUrl
        }
        role
      }
    }
  }
`)

export const GET_ISSUE: TypedDocumentNode<GetIssueQuery, GetIssueQueryVariables> = graphql(`
  query GetIssue($id: String!) {
    issue(id: $id) {
      id
      ticketId
      title
      createdAt
      description
      status
      level
      justification
      reasons {
        title
        justification
        reference
        referenceUrl
      }
      fragmentItem {
        id
        type
        createdAt
        updatedAt
        partnerSource {
          name
          config
        }
        data {
          ... on WebsitePage {
            __typename
            canonicalUrl
            screenshotUrl
          }
          ... on TrustpilotReview {
            __typename
            title
            consumer {
              displayName
            }
            text
            id
            rating
            pending
            location
          }

          ... on GoogleNewsSearchResultMetadata {
            __typename
            title
            date
          }

          ... on TikTokPostMetadata {
            __typename
            mediaUrls
            webVideoUrl
            videoMeta {
              coverUrl
            }
            authorMeta {
              name
            }
          }

          ... on RedditPost {
            __typename
            id
            username
            url
            body
            communityName
            redditCreatedAt
            upVotes
            numberOfComments
          }

          ... on CFPBComplaintMetadata {
            __typename
            complaint_id
            company
            complaint_what_happened
            has_narrative
            issue
            product
            state
          }
        }
      }
    }
  }
`)

export const GET_ENRICHED_TIKTOK_POST_FRAGMENT: TypedDocumentNode<
  GetEnrichedTiktokPostFragmentQuery,
  GetEnrichedTiktokPostFragmentQueryVariables
> = graphql(`
  query GetEnrichedTiktokPostFragment($enrichedFragmentId: String!) {
    enrichedTiktokPostFragment(enrichedFragmentId: $enrichedFragmentId) {
      id
      discriminator
      fragment {
        id
        discriminator
      }
      metadata {
        text
        webUrl
        playUrl
        coverImageUrl
      }
    }
  }
`)

export const GET_FRAGMENTS_BY_PARTNER_SOURCE_ID: TypedDocumentNode<
  GetAllFragmentsPartnerSourceIdQuery,
  GetAllFragmentsPartnerSourceIdQueryVariables
> = graphql(`
  query GetAllFragmentsPartnerSourceId($partnerSourceId: String!) {
    getAllFragmentsPartnerSourceId(partnerSourceId: $partnerSourceId) {
      id
      type
      ukey
      createdAt
      updatedAt
      partnerSource {
        name
        config
      }
      data {
        ... on WebsitePage {
          __typename
          canonicalUrl
          screenshotUrl
        }
        ... on TrustpilotReview {
          __typename
          title
          consumer {
            displayName
          }
          text
          id
          rating
          pending
          location
        }

        ... on GoogleNewsSearchResultMetadata {
          __typename
          title
          date
        }

        ... on TikTokPostMetadata {
          __typename
          mediaUrls
          webVideoUrl
          videoMeta {
            coverUrl
          }
          authorMeta {
            name
          }
        }

        ... on RedditPost {
          __typename
          id
          username
          url
          body
          communityName
          redditCreatedAt
          upVotes
          numberOfComments
        }

        ... on CFPBComplaintMetadata {
          __typename
          complaint_id
          company
          complaint_what_happened
          has_narrative
          issue
          product
          state
        }
      }
    }
  }
`)

export const GET_ENRICHED_FRAGMENTS_BY_FRAGMENT_ID: TypedDocumentNode<
  GetEnrichedFragmentByFragmentIdQuery,
  GetEnrichedFragmentByFragmentIdQueryVariables
> = graphql(`
  query GetEnrichedFragmentByFragmentId($fragmentId: String!) {
    getEnrichedFragmentByFragmentId(fragmentId: $fragmentId) {
      id
      type
      createdAt
      updatedAt
      ukey
      enrichedData {
        ... on EnrichedTrustPilotReviewMetadata {
          __typename
          category
          keywords
          summary
          threatensLegalAction
          mentionsDispute
        }

        ... on EnrichedCFPBComplaintMetadata {
          __typename
          category
          keywords
          summary
          threatensLegalAction
          mentionsDispute
        }

        ... on EnrichedWebsitePageMetadata {
          __typename
          text
          suggestions {
            highlightedText
            reasonForChange
            highLevelReason
            potentialAlternateCopy
          }
        }
      }
      data {
        ... on WebsitePage {
          __typename
          canonicalUrl
          screenshotUrl
          html
        }
        ... on TrustpilotReview {
          __typename
          title
          consumer {
            displayName
          }
          text
          id
          rating
          pending
          location
        }

        ... on GoogleNewsSearchResultMetadata {
          __typename
          title
          date
        }

        ... on TikTokPostMetadata {
          __typename
          mediaUrls
          webVideoUrl
          videoMeta {
            coverUrl
          }
          authorMeta {
            name
          }
        }

        ... on RedditPost {
          __typename
          id
          username
          url
          body
          communityName
          redditCreatedAt
          upVotes
          numberOfComments
        }

        ... on CFPBComplaintMetadata {
          __typename
          complaint_id
          company
          complaint_what_happened
          has_narrative
          issue
          product
          state
        }
      }
    }
  }
`)

export const GET_MATCHING_FRAGMENTS_BY_UKEY: TypedDocumentNode<GetFragmentByIdQuery, GetFragmentByIdQueryVariables> =
  graphql(`
    query GetFragmentById($fragmentId: String!) {
      getFragment(fragmentId: $fragmentId) {
        id
        type
        createdAt
        updatedAt
        ukey
        partnerSource {
          config
          name
        }
        data {
          ... on WebsitePage {
            __typename
            canonicalUrl
            screenshotUrl
          }
          ... on TrustpilotReview {
            __typename
            title
            consumer {
              displayName
            }
            text
            id
            rating
            pending
            location
          }

          ... on GoogleNewsSearchResultMetadata {
            __typename
            title
            date
          }

          ... on TikTokPostMetadata {
            __typename
            mediaUrls
            webVideoUrl
            videoMeta {
              coverUrl
            }
            authorMeta {
              name
            }
          }

          ... on RedditPost {
            __typename
            id
            username
            url
            body
            communityName
            redditCreatedAt
            upVotes
            numberOfComments
          }

          ... on CFPBComplaintMetadata {
            __typename
            complaint_id
            company
            complaint_what_happened
            has_narrative
            issue
            product
            state
          }
        }
      }
    }
  `)

export const GET_FRAGMENTS_BY_PARTNERSHIP_ID: TypedDocumentNode<
  GetAllFragmentsForPartnershipIdQuery,
  GetAllFragmentsForPartnershipIdQueryVariables
> = graphql(`
  query GetAllFragmentsForPartnershipId($input: GetFragmentsForPartnershipInput!) {
    getAllFragmentsForPartnershipId(input: $input) {
      id
      type
      createdAt
      updatedAt
      ukey
      partnerSource {
        config
        name
      }
      data {
        ... on WebsitePage {
          __typename
          canonicalUrl
          screenshotUrl
        }
        ... on TrustpilotReview {
          __typename
          title
          consumer {
            displayName
          }
          text
          id
          rating
          pending
          location
        }

        ... on GoogleNewsSearchResultMetadata {
          __typename
          title
          date
        }

        ... on TikTokPostMetadata {
          __typename
          mediaUrls
          webVideoUrl
          videoMeta {
            coverUrl
          }
          authorMeta {
            name
          }
        }

        ... on RedditPost {
          __typename
          id
          username
          url
          body
          communityName
          redditCreatedAt
          upVotes
          numberOfComments
        }

        ... on CFPBComplaintMetadata {
          __typename
          complaint_id
          company
          complaint_what_happened
          has_narrative
          issue
          product
          state
        }
      }
    }
  }
`)

export const GET_ISSUES_BY_PARTNER_SOURCE: TypedDocumentNode<
  GetIssuesByPartnerSourceIdQuery,
  GetIssuesByPartnerSourceIdQueryVariables
> = graphql(`
  query GetIssuesByPartnerSourceId($partnerSourceId: String!) {
    issues(partnerSourceId: $partnerSourceId) {
      id
      title
      createdAt
      description
      status
      level
      justification
      fragmentItem {
        id
        type
        createdAt
        updatedAt
        partnerSource {
          name
          config
        }
        data {
          ... on WebsitePage {
            __typename
            canonicalUrl
            screenshotUrl
          }
          ... on TrustpilotReview {
            __typename
            title
            consumer {
              displayName
            }
            text
            id
            rating
            pending
            location
          }

          ... on GoogleNewsSearchResultMetadata {
            __typename
            title
            date
          }

          ... on TikTokPostMetadata {
            __typename
            mediaUrls
            webVideoUrl
            videoMeta {
              coverUrl
            }
            authorMeta {
              name
            }
          }

          ... on RedditPost {
            __typename
            id
            username
            url
            body
            communityName
            redditCreatedAt
            upVotes
            numberOfComments
          }

          ... on CFPBComplaintMetadata {
            __typename
            complaint_id
            company
            complaint_what_happened
            has_narrative
            issue
            product
            state
          }
        }
      }
    }
  }
`)

export const GET_ISSUES_BY_PARTNERSHIP: TypedDocumentNode<
  GetIssuesByPartnershipQuery,
  GetIssuesByPartnershipQueryVariables
> = graphql(`
  query GetIssuesByPartnership($partnershipId: String!) {
    issuesByPartnership(partnershipId: $partnershipId) {
      id
      ticketId
      title
      createdAt
      description
      status
      level
      justification
      reasons {
        title
        justification
        reference
        referenceUrl
      }
      fragmentItem {
        id
        type
        createdAt
        updatedAt
        partnerSource {
          name
          config
        }
        data {
          ... on WebsitePage {
            __typename
            canonicalUrl
            screenshotUrl
          }
          ... on TrustpilotReview {
            __typename
            title
            consumer {
              displayName
            }
            text
            id
            rating
            pending
            location
          }

          ... on GoogleNewsSearchResultMetadata {
            __typename
            title
            date
          }

          ... on TikTokPostMetadata {
            __typename
            mediaUrls
            webVideoUrl
            videoMeta {
              coverUrl
            }
            authorMeta {
              name
            }
          }

          ... on RedditPost {
            __typename
            id
            username
            url
            body
            communityName
            redditCreatedAt
            upVotes
            numberOfComments
          }

          ... on CFPBComplaintMetadata {
            __typename
            complaint_id
            company
            complaint_what_happened
            has_narrative
            issue
            product
            state
          }
        }
      }
    }
  }
`)

export const GET_ASSET_UPLOAD_URL: TypedDocumentNode<GetAssetUploadUrlQuery, GetAssetUploadUrlQueryVariables> = graphql(
  `
    query GetAssetUploadUrl($type: MimeType) {
      assetUploadUrl(input: { type: $type }) {
        signedUrl
        fileKey
      }
    }
  `,
)

export const GET_NEW_ASSET_VERSION_UPLOAD_URL: TypedDocumentNode<
  GetNewAssetVersionUploadUrlQuery,
  GetNewAssetVersionUploadUrlQueryVariables
> = graphql(`
  query GetNewAssetVersionUploadUrl($id: String!, $mimeType: MimeType!) {
    assetVersionUpdateUrl(id: $id, mimeType: $mimeType) {
      signedUrl
      fileKey
    }
  }
`)

export const GET_ASSET_DOWNLOAD_URL: TypedDocumentNode<GetAssetDownloadUrlQuery, GetAssetDownloadUrlQueryVariables> =
  graphql(`
    query GetAssetDownloadUrl($id: String!, $versionId: String) {
      assetDownloadUrl(input: { assetId: $id, versionId: $versionId }) {
        signedUrl
        fileKey
        mimeType
      }
    }
  `)

export const GET_ASSET_REVIEW_REQUESTS: TypedDocumentNode<GetReviewRequestsQuery, GetReviewRequestsQueryVariables> =
  graphql(`
    query GetReviewRequests($assetId: String!) {
      reviewRequests(input: { assetId: $assetId }) {
        id
        requesterUser {
          id
          firstName
          lastName
          avatarUrl
        }
        status
      }
    }
  `)

export const GET_ALL_REVISIONS: TypedDocumentNode<GetAllRevisionsQuery, GetAllRevisionsQueryVariables> = graphql(`
  query GetAllRevisions($assetId: String!) {
    documentAssetRevisions(input: { assetId: $assetId }) {
      attributes
      authorsIds
      createdAt
      creatorId
      fromVersion
      id
      name
      toVersion
      externalId
      updatedAt
    }
  }
`)

export const GET_REVISION: TypedDocumentNode<GetRevisionQuery, GetRevisionQueryVariables> = graphql(`
  query GetRevision($assetId: String!, $revisionId: String!) {
    documentAssetRevision(input: { assetId: $assetId, revisionId: $revisionId }) {
      attributes
      authorsIds
      createdAt
      creatorId
      fromVersion
      diffData
      id
      name
      toVersion
      externalId
      updatedAt
    }
  }
`)

export const GET_PROJECT_TASK: TypedDocumentNode<GetProjectTaskQuery, GetProjectTaskQueryVariables> = graphql(`
  query GetProjectTask($taskId: String!) {
    projectTask(taskId: $taskId) {
      id
      name
      createdAt
      asset {
        id
        name
        key
        version
        originalMimeType
        suggestionsJobRunning
      }
      status
      description
      dueDate
      archivedAt
      priority
      requirementId
      partnershipId
      assignee {
        id
        firstName
        lastName
        avatarUrl
      }
      reviewer {
        id
        firstName
        lastName
        avatarUrl
      }
      project {
        id
        name
        users {
          id
          user {
            id
            firstName
            lastName
            avatarUrl
          }
        }
      }
    }
  }
`)

export const GET_WORKFLOW_TEMPLATES: TypedDocumentNode<GetWorkflowTemplatesQuery, GetWorkflowTemplatesQueryVariables> =
  graphql(`
    query GetWorkflowTemplates(
      $organizationId: String!
      $onlyConstructed: Boolean! = false
      $projectId: String
      $active: Boolean
      $archived: Boolean
    ) {
      workflowTemplates(
        organizationId: $organizationId
        onlyConstructed: $onlyConstructed
        projectId: $projectId
        active: $active
        archived: $archived
      ) {
        id
        name
        description
        createdAt
        status
        active
        projectId
        createdBy {
          id
          firstName
          lastName
          avatarUrl
        }
        organization {
          id
          name
          logoUrl
        }
      }
    }
  `)

export const GET_WORKFLOW_TEMPLATE: TypedDocumentNode<GetWorkflowTemplateQuery, GetWorkflowTemplateQueryVariables> =
  graphql(`
    query GetWorkflowTemplate($id: String!) {
      workflowTemplate(id: $id) {
        id
        name
        description
        projectId
        nodes {
          id
          name
          type
          createdAt
          updatedAt
          payload
          children {
            id
            name
          }
        }
        rootNodeId
        status
        type
      }
    }
  `)

export const GET_ORGANIZATION_INVITATIONS: TypedDocumentNode<
  OrganizationInvitationsQuery,
  OrganizationInvitationsQueryVariables
> = graphql(`
  query OrganizationInvitations {
    organizationInvitations {
      id
      email
      role
      uniqueId
      organization {
        id
        name
        teamUrl
        type
      }
    }
  }
`)

export const SENT_ORGANIZATION_INVITATIONS: TypedDocumentNode<
  SentOrganizationInvitationsQuery,
  SentOrganizationInvitationsQueryVariables
> = graphql(`
  query SentOrganizationInvitations($organizationId: String!) {
    sentOrganizationInvitations(organizationId: $organizationId) {
      id
      email
    }
  }
`)

export const GET_ORGANIZATION_INVITATION: TypedDocumentNode<
  GetOrganizationInvitationQuery,
  GetOrganizationInvitationQueryVariables
> = graphql(`
  query GetOrganizationInvitation($uniqueId: String!) {
    organizationInvitation(uniqueId: $uniqueId) {
      id
      email
      role
      uniqueId
      organization {
        id
        name
        teamUrl
        type
      }
    }
  }
`)

export const GET_PRODUCTS_BY_ORGANIZATION: TypedDocumentNode<
  GetProductsByOrganizationQuery,
  GetProductsByOrganizationQueryVariables
> = graphql(`
  query GetProductsByOrganization($organizationId: String!) {
    getProductsByOrganization(organizationId: $organizationId) {
      product {
        id
        name
      }
      deletedAt
      activeAt
      isActive
    }
  }
`)

export const GET_PROJECT_INVITATION: TypedDocumentNode<GetProjectInvitationQuery, GetProjectInvitationQueryVariables> =
  graphql(`
    query GetProjectInvitation($uniqueId: String!) {
      projectInvitation(uniqueId: $uniqueId) {
        id
        email
        role
        uniqueId
        project {
          id
          name
          projectUrl
        }
      }
    }
  `)

export const GET_SPACE_INVITATION: TypedDocumentNode<GetSpaceInvitationQuery, GetSpaceInvitationQueryVariables> =
  graphql(`
    query GetSpaceInvitation($uniqueId: String!) {
      spaceInvitation(uniqueId: $uniqueId) {
        id
        email
        role
        uniqueId
        space {
          id
          name
          spaceUrl
        }
      }
    }
  `)

export const GET_SPACE_INVITATIONS_FOR_SPACE: TypedDocumentNode<
  GetSpaceInvitationsForSpaceQuery,
  GetSpaceInvitationsForSpaceQueryVariables
> = graphql(`
  query GetSpaceInvitationsForSpace($input: GetSpaceInvitationsForSpaceInput!) {
    spaceInvitationsForSpace(input: $input) {
      id
      email
      role
      uniqueId
      space {
        id
        name
        spaceUrl
      }
    }
  }
`)

export const GET_SPACES: TypedDocumentNode<GetSpacesQuery, GetSpacesQueryVariables> = graphql(`
  query GetSpaces($organizationId: String!) {
    spaces(organizationId: $organizationId) {
      ...SpacesQuery
    }
  }
`)

export const GET_SIDEBAR: TypedDocumentNode<GetSidebarQuery, GetSidebarQueryVariables> = graphql(`
  query GetSidebar($organizationId: String!) {
    spaces(organizationId: $organizationId) {
      ...SpacesQuery
    }
  }
`)

export const GET_PROJECT_INVITATIONS_FOR_USER: TypedDocumentNode<
  ProjectInvitationsForUserQuery,
  ProjectInvitationsForUserQueryVariables
> = graphql(`
  query ProjectInvitationsForUser($input: GetProjectInvitationsForUserInput!) {
    projectInvitationsForUser(input: $input) {
      id
      email
      role
      uniqueId
      project {
        id
        name
        projectUrl
      }
    }
  }
`)

export const GET_PROJECT_INVITATIONS_FOR_PROJECT: TypedDocumentNode<
  ProjectInvitationsForProjectQuery,
  ProjectInvitationsForProjectQueryVariables
> = graphql(`
  query ProjectInvitationsForProject($input: GetProjectInvitationsForProjectInput!) {
    projectInvitationsForProject(input: $input) {
      id
      email
      role
      uniqueId
      project {
        id
        name
        projectUrl
      }
    }
  }
`)

export const SENT_PROJECT_INVITATIONS: TypedDocumentNode<
  SentProjectInvitationsQuery,
  SentProjectInvitationsQueryVariables
> = graphql(`
  query SentProjectInvitations($projectId: String!) {
    sentProjectInvitations(projectId: $projectId) {
      email
      uniqueId
      inviter {
        id
      }
    }
  }
`)

export const GET_COMMENT_THREAD: TypedDocumentNode<GetCommentThreadQuery, GetCommentThreadQueryVariables> = graphql(`
  query GetCommentThread($input: GetCommentThreadInput!) {
    commentThread(input: $input) {
      id
      externalId
      attributes
      context
      resolvedAt
      resolvedBy {
        id
      }
      comments {
        id
        externalId
        content
        createdAt
        author {
          id
        }
        attributes
      }
    }
  }
`)

export const GET_INTEGRATIONS: TypedDocumentNode<GetIntegrationsQuery, GetIntegrationsQueryVariables> = graphql(`
  query GetIntegrations($organizationId: String!) {
    integrations(organizationId: $organizationId) {
      id
      createdAt
      deletedAt
      integrationProviderId
      isEnabled
      updatedAt
    }
  }
`)

export const GET_INTEGRATION: TypedDocumentNode<GetIntegrationQuery, GetIntegrationQueryVariables> = graphql(`
  query GetIntegration($organizationId: String!, $integrationProviderId: String!) {
    integration(integrationProviderId: $integrationProviderId, organizationId: $organizationId) {
      id
      createdAt
      deletedAt
      integrationProviderId
      isEnabled
      updatedAt
    }
  }
`)

export const GET_COMMENT_THREADS: TypedDocumentNode<GetCommentThreadsQuery, GetCommentThreadsQueryVariables> = graphql(`
  query GetCommentThreads($input: GetCommentThreadsInput!) {
    commentThreads(input: $input) {
      id
      externalId
      attributes
      context
      resolvedAt
      resolvedBy {
        id
      }
      comments {
        id
        externalId
        content
        createdAt
        author {
          id
        }
        attributes
      }
    }
  }
`)

export const GET_NOTIFICATION_PREFERENCES_PROJECT: TypedDocumentNode<
  GetNotificationPreferencesProjectQuery,
  GetNotificationPreferencesProjectQueryVariables
> = graphql(`
  query GetNotificationPreferencesProject($projectId: String!, $userId: String!) {
    notificationPreferencesProject(projectId: $projectId, userId: $userId) {
      id
      userId
      projectId
      user {
        id
      }
      project {
        id
      }
      notifyTasks
      notifyComments
      notifyStatusUpdates
      notifyStatusFrequency
      notifyEmail
      notifySlack
      muteAll
    }
  }
`)

export const GET_SLACK_CHANNELS: TypedDocumentNode<GetSlackChannelsQuery, GetSlackChannelsQueryVariables> = graphql(`
  query GetSlackChannels($organizationId: String!) {
    slackChannels(organizationId: $organizationId) {
      id
      name
    }
  }
`)

export const GET_SLACK_USERS: TypedDocumentNode<GetSlackUsersQuery, GetSlackUsersQueryVariables> = graphql(`
  query GetSlackUsers($organizationId: String!) {
    slackUsers(organizationId: $organizationId) {
      id
      realName
    }
  }
`)
export const GET_NOTIFICATION_PREFERENCES_SPACE: TypedDocumentNode<
  GetNotificationPreferencesSpaceQuery,
  GetNotificationPreferencesSpaceQueryVariables
> = graphql(`
  query GetNotificationPreferencesSpace($spaceId: String!, $userId: String!) {
    notificationPreferencesSpace(spaceId: $spaceId, userId: $userId) {
      id
      spaceId
      userId
      space {
        id
        name
        spaceUrl
      }
      user {
        id
        firstName
        lastName
        email
      }
      notifyProjectCreated
      notifyAddedToProject
      notifyEmail
      notifySlack
      muteAll
    }
  }
`)

export const GET_SLACK_CHANNEL: TypedDocumentNode<GetSlackChannelQuery, GetSlackChannelQueryVariables> = graphql(`
  query GetSlackChannel($organizationId: String!) {
    slackChannel(organizationId: $organizationId) {
      id
      name
    }
  }
`)

export const GET_SLACK_TEAM_INFO: TypedDocumentNode<GetSlackTeamInfoQuery, GetSlackTeamInfoQueryVariables> = graphql(`
  query GetSlackTeamInfo($organizationId: String!) {
    slackTeamInfo(organizationId: $organizationId) {
      id
      name
      url
      domain
      emailDomain
      icon
    }
  }
`)

export const GET_SLACK_DATA_CHANNEL: TypedDocumentNode<GetSlackDataChannelQuery, GetSlackDataChannelQueryVariables> =
  graphql(`
    query GetSlackDataChannel($organizationId: String!, $collection: String!, $objectId: String!) {
      slackDataChannel(organizationId: $organizationId, collection: $collection, objectId: $objectId) {
        collection
        objectId
        id
        name
      }
    }
  `)

export const GET_NOTIFICATION_PREFERENCES_USER: TypedDocumentNode<
  GetNotificationPreferencesUserQuery,
  GetNotificationPreferencesUserQueryVariables
> = graphql(`
  query GetNotificationPreferencesUser($userId: String!) {
    notificationPreferencesUser(userId: $userId) {
      id
      userId
      user {
        id
        firstName
        lastName
        notificationPreferencesProject {
          id
          projectId
          notifyTasks
          notifyComments
          notifyStatusUpdates
          notifyStatusFrequency
          notifyEmail
          notifySlack
          muteAll
        }
        notificationPreferencesSpace {
          id
          spaceId
          notifyProjectCreated
          notifyAddedToProject
          notifyEmail
          notifySlack
          muteAll
        }
      }
      notifyEmail
      notifySlack
      muteAll
    }
  }
`)

export const GET_SUGGESTIONS_FOR_ASSET: TypedDocumentNode<
  GetSuggestionsForAssetQuery,
  GetSuggestionsForAssetQueryVariables
> = graphql(`
  query GetSuggestionsForAsset($input: GetSuggestionsInput!) {
    suggestions(input: $input) {
      id
      highlightedText
      reasonForChange
      highLevelReason
      potentialAlternateCopy
      createdAt
      resolveType
      createdBy {
        id
        firstName
        lastName
      }
      asset {
        user {
          id
        }
        id
        name
        key
        version
      }
      metadata {
        coordinates {
          points
          layout_width
          layout_height
        }
        page_number
      }
    }
  }
`)

export const RESOLVE_SUGGESTION: TypedDocumentNode<ResolveSuggestionQuery, ResolveSuggestionQueryVariables> = graphql(`
  query ResolveSuggestion($input: ResolveSuggestionInput!) {
    resolveSuggestion(input: $input)
  }
`)

export const GET_ORGANIZATION_LOGO_UPLOAD_URL: TypedDocumentNode<
  GetOrganizationLogoUploadUrlQuery,
  GetOrganizationLogoUploadUrlQueryVariables
> = graphql(`
  query GetOrganizationLogoUploadUrl($input: GetLogoUploadUrlInput!) {
    logoUploadUrl(input: $input) {
      signedUrl
      fileKey
    }
  }
`)

export const GET_MY_TASKS: TypedDocumentNode<
  GetTasksForUserAndOrganizationQuery,
  GetTasksForUserAndOrganizationQueryVariables
> = graphql(`
  query getTasksForUserAndOrganization($organizationId: String!) {
    getTasksForUserAndOrganization(organizationId: $organizationId) {
      id
      name
      status
      description
      dueDate
      priority
      createdAt
      updatedAt
      archivedAt
      requirementId
      partnershipId
      reviewer {
        id
      }
      workflow {
        id
        status
      }
      assignee {
        id
        firstName
        lastName
        avatarUrl
      }
      asset {
        id
        name
        key
        version
        originalMimeType
      }
      project {
        id
        name
        projectUrl
        space {
          id
          name
          spaceUrl
        }
        users {
          id
          role
          user {
            id
            firstName
            lastName
            email
          }
        }
      }
    }
  }
`)

export const GET_ASSET_EDITS: TypedDocumentNode<GetAssetEditsQuery, GetAssetEditsQueryVariables> = graphql(`
  query GetAssetEdits($assetId: String!) {
    assetEdits(assetId: $assetId) {
      id
      addedText
      removedText
      fromVersionId
      toVersionId
      createdAt
      user {
        id
        firstName
        lastName
        avatarUrl
      }
    }
  }
`)

export const GET_PROJECT_TASK_ATTACHMENT_DOWNLOAD_URL: TypedDocumentNode<
  ProjectTaskAttachmentDownloadUrlQuery,
  ProjectTaskAttachmentDownloadUrlQueryVariables
> = graphql(`
  query projectTaskAttachmentDownloadUrl($attachmentId: String!) {
    projectTaskAttachmentDownloadUrl(attachmentId: $attachmentId) {
      signedUrl
      fileKey
      mimeType
    }
  }
`)

export const GET_ACTIVITY_TASK_ATTACHMENT_DOWNLOAD_URL: TypedDocumentNode<
  GetActivityTaskAttachmentDownloadUrlQuery,
  GetActivityTaskAttachmentDownloadUrlQueryVariables
> = graphql(`
  query GetActivityTaskAttachmentDownloadUrl($attachmentId: String!) {
    activityTaskAttachmentDownloadUrl(attachmentId: $attachmentId) {
      signedUrl
      fileKey
      mimeType
    }
  }
`)

export const GET_ACTIVE_NODE_FOR_TASK: TypedDocumentNode<
  GetActiveNodeForTaskQuery,
  GetActiveNodeForTaskQueryVariables
> = graphql(`
  query GetActiveNodeForTask($taskId: String!) {
    getActiveNodeForTask(taskId: $taskId) {
      id
      name
      type
      payload
      status
      children {
        id
        name
        type
        payload
        status
      }
      workflow {
        id
        status
        name
      }
    }
  }
`)

// TODO - remove this query once the new query is in place
export const GET_ASSET_VERSIONS: TypedDocumentNode<GetAssetVersionsQuery, GetAssetVersionsQueryVariables> = graphql(`
  query GetAssetVersions($assetId: String!) {
    assetVersions(input: { assetId: $assetId }) {
      versionId
      lastModified
      size
      eTag
      isLatest
      signedUrl
      comments
    }
  }
`)

export const GET_CURRENT_ASSET_VERSIONS: TypedDocumentNode<
  GetCurrentAssetVersionsQuery,
  GetCurrentAssetVersionsQueryVariables
> = graphql(`
  query GetCurrentAssetVersions($assetId: String!) {
    currentAssetVersions(input: { assetId: $assetId }) {
      id
      roomId
      label
      mimeType
      file {
        fileKey
        signedUrl
        mimeType
      }
      editableMimeType
      editableFile {
        fileKey
        signedUrl
        mimeType
      }
    }
  }
`)

export const GET_ORGANIZATION_PROGRAMS: TypedDocumentNode<
  GetProgramsForOrganizationQuery,
  GetProgramsForOrganizationQueryVariables
> = graphql(`
  query GetProgramsForOrganization($organizationId: String!) {
    getProgramsForOrganization(input: { organizationId: $organizationId }) {
      id
      createdBy {
        id
        firstName
        lastName
        avatarUrl
      }
      productType
      programType
      createdAt
      name
      description
      isActive
      programRequirements {
        requirement {
          id
          name
          description
          category
          mimeTypes
        }
      }
    }
  }
`)

export const GET_PROGRAM_BY_ID: TypedDocumentNode<GetProgramByIdQuery, GetProgramByIdQueryVariables> = graphql(`
  query GetProgramById($id: String!) {
    program(id: $id) {
      id
      createdBy {
        id
        firstName
        lastName
        avatarUrl
      }
      productType
      programType
      createdAt
      name
      description
      isActive
      programRequirements {
        requirement {
          id
          name
          description
          category
          mimeTypes
        }
      }
    }
  }
`)

export const GET_ORGANIZATION_PARTNERSHIPS: TypedDocumentNode<
  GetPartnershipsForOrganizationQuery,
  GetPartnershipsForOrganizationQueryVariables
> = graphql(`
  query GetPartnershipsForOrganization($organizationId: String!) {
    getPartnershipsForOrganization(input: { organizationId: $organizationId }) {
      id
      createdAt
      updatedAt
      stage
      logoUrl
      healthScore

      owner {
        id
        email
        firstName
        lastName
        avatarUrl
      }
      partnerOrganization {
        id
        name
        metadata
        type
      }
      program {
        id
        name
        description
        programType
        productType
        isActive
      }
    }
  }
`)

export const GET_PROJECT_BY_ID: TypedDocumentNode<GetProjectByIdQuery, GetProjectByIdQueryVariables> = graphql(`
  query GetProjectById($id: String!) {
    project(id: $id) {
      id
      name
      projectUrl
      description
      isPublic
      createdAt
      icon
      iconColor
      createdBy {
        firstName
        lastName
        id
      }
      space {
        id
        name
        spaceUrl
      }
      tasks {
        id
        name
        description
        dueDate
        priority
        status
        archivedAt
        updatedAt
        asset {
          id
          name
          key
          version
          originalMimeType
        }
        assignee {
          id
          firstName
          lastName
          avatarUrl
        }
        reviewer {
          id
          firstName
          lastName
        }
        comments {
          id
          content
          createdAt
          author {
            id
            firstName
            lastName
            avatarUrl
            email
          }
        }
        attachments {
          id
          fileKey
          name
        }
        createdAt
      }
      users {
        id
        user {
          id
          firstName
          lastName
          email
          avatarUrl
        }
        role
      }
    }
  }
`)

export const REQUIREMENT_CATEGORY_SUMMARIES: TypedDocumentNode<
  RequirementCategorySummariesQuery,
  RequirementCategorySummariesQueryVariables
> = graphql(`
  query RequirementCategorySummaries($partnershipId: String!) {
    requirementCategories(partnershipId: $partnershipId) {
      category
      documentCount
      inProgressDocumentCount
      completedDocumentCount

      status
      program {
        id
        createdAt
        name
        description
      }
      programId
      progress

      tasks {
        id
        name
        description
        status
        dueDate
        archivedAt
        assignee {
          id
          avatarUrl
          firstName
          lastName
        }

        asset {
          id
          name
          key
          version
          originalMimeType
        }

        requirement {
          id
          name
          description
          category
          mimeTypes
        }
      }
    }
  }
`)

export const SEARCH: TypedDocumentNode<SearchQuery, SearchQueryVariables> = graphql(`
  query Search($input: SearchFilters!, $organizationId: String!, $pagination: Pagination!) {
    search(input: $input, organizationId: $organizationId, pagination: $pagination) {
      data {
        object {
          ...AssetSearch
          ...OrganizationSearch
          ...SpaceSearch
          ...ProjectSearch
        }
        link
      }
      meta {
        ...PaginationSearch
      }
    }
  }
`)

export const FILTER_AUDIT_TRAIL: TypedDocumentNode<FilterAuditTrailQuery, FilterAuditTrailQueryVariables> = graphql(`
  query FilterAuditTrail($input: FilterAuditTrailInput!) {
    filterAuditTrail(input: $input) {
      ...AuditTrail
    }
  }
`)

export const FILTER_AUDIT_TRAIL_BY_ENTITY_ID: TypedDocumentNode<
  FilterAuditTrailByEntityIdsQuery,
  FilterAuditTrailByEntityIdsQueryVariables
> = graphql(`
  query FilterAuditTrailByEntityIds($input: FilterAuditTrailByEntityIdsInput!) {
    filterAuditTrailByEntityIds(input: $input) {
      ...AuditTrail
    }
  }
`)

export const GET_PROJECT_TASK_AUDIT_TRAIL_BY_WORKFLOW_TEMPLATE: TypedDocumentNode<
  GetProjectTaskAuditTrailByWorkflowTemplateQuery,
  GetProjectTaskAuditTrailByWorkflowTemplateQueryVariables
> = graphql(`
  query GetProjectTaskAuditTrailByWorkflowTemplate($organizationId: String!, $workflowTemplateId: String!) {
    getProjectTaskAuditTrailByWorkflowTemplate(
      organizationId: $organizationId
      workflowTemplateId: $workflowTemplateId
    ) {
      ...AuditTrail
    }
  }
`)

export const GET_PROCESS: TypedDocumentNode<GetProcessQuery, GetProcessQueryVariables> = graphql(`
  query GetProcess($id: String!) {
    process(id: $id) {
      ...ProcessFields
    }
  }
`)

export const GET_PROCESSES: TypedDocumentNode<GetProcessesQuery, GetProcessesQueryVariables> = graphql(`
  query GetProcesses($organizationId: String!) {
    processes(organizationId: $organizationId) {
      ...ProcessFields
    }
  }
`)

export const GET_SPONSOR: TypedDocumentNode<GetSponsorQuery, GetSponsorQueryVariables> = graphql(`
  query GetSponsor($input: GetSponsorInput!) {
    getSponsorOrg(input: $input) {
      id
    }
  }
`)

export const GET_PARTNER_SOURCES_FOR_PARTNERSHIP: TypedDocumentNode<
  GetPartnerSourcesForPartnershipQuery,
  GetPartnerSourcesForPartnershipQueryVariables
> = graphql(`
  query GetPartnerSourcesForPartnership($partnershipId: String!) {
    getAllForPartnershipId(partnershipId: $partnershipId) {
      id
      createdAt
      config
      name
      type
      syncedAt
    }
  }
`)

export const GET_PARTNER_SOURCES_WITH_COUNTS_FOR_PARTNERSHIP: TypedDocumentNode<
  GetPartnerSourcesWithCountsForPartnershipQuery,
  GetPartnerSourcesWithCountsForPartnershipQueryVariables
> = graphql(`
  query GetPartnerSourcesWithCountsForPartnership($partnershipId: String!) {
    getAllWithCountsForPartnershipId(partnershipId: $partnershipId) {
      id
      name
      createdAt
      config
      syncedAt
      fragmentCount
      issueCount
    }
  }
`)

export const GET_PARTNER_SOURCE_BY_ID: TypedDocumentNode<
  GetPartnerSourceByIdQuery,
  GetPartnerSourceByIdQueryVariables
> = graphql(`
  query GetPartnerSourceById($partnerSourceId: String!) {
    getPartnerSourceById(partnerSourceId: $partnerSourceId) {
      id
      name
      createdAt
      config
      type
      syncedAt
    }
  }
`)

export const GET_RULES_BY_PARTNER_SOURCE: TypedDocumentNode<
  GetRuleByPartnerSourceQuery,
  GetRuleByPartnerSourceQueryVariables
> = graphql(`
  query GetRuleByPartnerSource($partnerSourceId: String!) {
    rulesByPartnerSource(partnerSourceId: $partnerSourceId) {
      id
      name
      conditionGroups {
        conditions {
          operator
          value
          regulation
        }
      }
    }
  }
`)

export const GET_ACTIVITIES: TypedDocumentNode<GetActivitiesQuery, GetActivitiesQueryVariables> = graphql(`
  query GetActivities {
    getActivities {
      ...ActivityDetails
    }
  }
`)

export const GET_ACTIVITY: TypedDocumentNode<ActivityQuery, ActivityQueryVariables> = graphql(`
  query Activity($id: String!) {
    activity(id: $id) {
      ...ActivityDetails
    }
  }
`)

export const GET_BANKS: TypedDocumentNode<OrganizationsByTypeQuery, OrganizationsByTypeQueryVariables> = graphql(`
  query OrganizationsByType($type: String!) {
    organizationsByType(type: $type) {
      id
      name
      teamUrl
      logoUrl
      metadata
      type
      organizationProducts {
        product {
          id
          name
        }
      }
    }
  }
`)

export const GET_ADMIN_BANK_REPORT: TypedDocumentNode<GetAdminBankReportQuery, GetAdminBankReportQueryVariables> =
  graphql(`
    query GetAdminBankReport {
      getAdminBankReport {
        id
        description
        reports {
          id
          name
          overallHealth
          topics {
            id
            name
            healthStatus
          }
        }
      }
    }
  `)

export const GET_ALL_PARTNERS: TypedDocumentNode<
  GetAllMosaicPartnershipsQuery,
  GetAllMosaicPartnershipsQueryVariables
> = graphql(`
  query GetAllMosaicPartnerships {
    getAllMosaicPartnerships {
      id
      partnerOrganization {
        id
        name
      }
      sponsorOrganization {
        id
        name
      }
    }
  }
`)

export const GET_FINTECHS: TypedDocumentNode<
  GetMosaicPartnershipsForOrganizationQuery,
  GetMosaicPartnershipsForOrganizationQueryVariables
> = graphql(`
  query GetMosaicPartnershipsForOrganization($input: GetSponsorshipsInput!) {
    getMosaicPartnershipsForOrganization(input: $input) {
      id
      productType
      programType
      partnerOrganization {
        id
        name
      }
    }
  }
`)

export const GET_ACTIVITY_SUBSCRIPTIONS_FOR_ACTIVITY: TypedDocumentNode<
  GetActivitySubscriptionsForActivityQuery,
  GetActivitySubscriptionsForActivityQueryVariables
> = graphql(`
  query GetActivitySubscriptionsForActivity($id: String!, $organizationType: OrganizationType, $sourceBankId: String) {
    getActivitySubscriptionsForActivity(id: $id, organizationType: $organizationType, sourceBankId: $sourceBankId) {
      id
      activity {
        id
        name
        description
      }
      organization {
        id
        name
        type
      }
      sourceBank {
        id
        name
        type
      }
      dueDate
      description
      status
      inheritedFromBank
      optedOut
      createdAt
      updatedAt
      deletedAt
    }
  }
`)

export const GET_ACTIVITY_SUBSCRIPTIONS_FOR_ORG: TypedDocumentNode<
  GetSubscriptionsForOrganizationQuery,
  GetSubscriptionsForOrganizationQueryVariables
> = graphql(`
  query GetSubscriptionsForOrganization($orgId: String!) {
    getSubscriptionsForOrganization(orgId: $orgId) {
      id
      activity {
        id
      }
    }
  }
`)

export const GET_ACTIVITY_TASK: TypedDocumentNode<ActivityTaskQuery, ActivityTaskQueryVariables> = graphql(`
  query ActivityTask($taskId: String!) {
    activityTask(taskId: $taskId) {
      ...ActivityTaskDetails
    }
  }
`)

export const GET_ACTIVITY_TASKS_FOR_SUBSCRIBER: TypedDocumentNode<
  GetActivityTasksForSubscriberQuery,
  GetActivityTasksForSubscriberQueryVariables
> = graphql(`
  query GetActivityTasksForSubscriber($input: GetActivityTasksInput!) {
    getActivityTasksForSubscriber(input: $input) {
      ...ActivityTaskDetails
    }
  }
`)

export const GET_ACTIVITY_TASKS_FOR_TODO: TypedDocumentNode<
  GetActivityTasksForTodoQuery,
  GetActivityTasksForTodoQueryVariables
> = graphql(`
  query GetActivityTasksForTodo($input: GetActivityTasksInput!) {
    getActivityTasksForTodo(input: $input) {
      ...ActivityTaskDetails
    }
  }
`)

export const GET_ACTIVITY_TASKS_FOR_PARTNER_TODO: TypedDocumentNode<
  GetActivityTasksForPartnerTodoQuery,
  GetActivityTasksForPartnerTodoQueryVariables
> = graphql(`
  query GetActivityTasksForPartnerTodo($input: GetActivityTasksInput!) {
    getActivityTasksForPartnerTodo(input: $input) {
      ...ActivityTaskDetails
    }
  }
`)

export const GET_ACTIVITY_TASKS_FOR_FINTECH: TypedDocumentNode<
  GetActivityTasksForFintechQuery,
  GetActivityTasksForFintechQueryVariables
> = graphql(`
  query GetActivityTasksForFintech($input: GetActivityTasksInput!) {
    getActivityTasksForFintech(input: $input) {
      ...ActivityTaskDetails
    }
  }
`)

export const GET_ACTIVITY_TASKS_BY_FILTER: TypedDocumentNode<
  GetActivityTasksByFilterQuery,
  GetActivityTasksByFilterQueryVariables
> = graphql(`
  query GetActivityTasksByFilter($input: GetActivityTasksInput!) {
    getActivityTasksByFilter(input: $input) {
      ...ActivityTaskDetails
    }
  }
`)

export const GET_ACTIVITY_TASKS_STATUS_COUNT: TypedDocumentNode<
  GetActivityTasksStatusCountQuery,
  GetActivityTasksStatusCountQueryVariables
> = graphql(`
  query GetActivityTasksStatusCount($input: ActivityStatusCountInput!) {
    getActivityTasksByStatusCount(input: $input) {
      status
      count
    }
  }
`)

export const GET_MOSAIC_ACTIVITY_REPORT: TypedDocumentNode<GetReportQuery, GetReportQueryVariables> = graphql(`
  query GetReport($input: CreateMosaicReportInput!) {
    getReport(input: $input) {
      type
      report {
        ... on MosaicActivityReport {
          id
          __typename
          description
          activity {
            id
            name
            description
            topic {
              name
            }
            subtopic {
              name
            }
            responsibleParty
            updateFrequency
          }
          items {
            id
            complianceStatus
            partnerName
            activityTasks {
              ...ActivityTaskDetails
            }
            latestActivityTaskSummary {
              lastRequestedAt
              lastSubmittedAt
              comments {
                author {
                  firstName
                }
                content
              }
              submittingUserExplanation
              approvalUserExplanation
            }
          }
        }
        ... on MosaicActivitiesReport {
          id
          __typename
          description
          reports {
            id
            description
            activity {
              id
              name
              description
              topic {
                name
              }
              subtopic {
                name
              }
              responsibleParty
              updateFrequency
            }
            items {
              id
              complianceStatus
              partnerName
              activityTasks {
                ...ActivityTaskDetails
              }
              latestActivityTaskSummary {
                lastRequestedAt
                lastSubmittedAt
                comments {
                  author {
                    firstName
                  }
                  content
                }
                submittingUserExplanation
                approvalUserExplanation
              }
            }
          }
        }
        ... on MosaicBankReport {
          id
          # More fields specific to MosaicBankReport
        }
        ... on MosaicFintechReport {
          id
          # More fields specific to MosaicFintechReport
        }
      }
    }
  }
`)

export const GET_ACTIVE_ACTIVITIES_FOR_ORG: TypedDocumentNode<
  GetActiveActivitiesForOrganizationQuery,
  GetActiveActivitiesForOrganizationQueryVariables
> = graphql(`
  query GetActiveActivitiesForOrganization($orgId: String!) {
    getActiveActivitiesForOrganization(orgId: $orgId) {
      ...ActivityDetails
    }
  }
`)
